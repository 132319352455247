<script>
import moment from 'moment'
import serverConfig from '@/helpers/config';
import { useCookies } from "vue3-cookies";
import Swal from "sweetalert2";
import axios from 'axios';


import documentManagerUT from "@/components/widgets/projects/documentManagerUT";

export default {
  name: "accountingManager",
  props: [
    'ID',
    'data',
  ],
  setup() {
   
    moment.locale('it')

    const { cookies } = useCookies();    
    let tk = 'Bearer '+cookies.get('tk')

    return { tk };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        item:{
          accountingDetails:{
              contract:false,
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },
          tipoImpiantoFatturato:false,
          fatturazioneAvviata:false,
          fatturazioneAvviataStatus:false,         
        },
        famList:[],
        productCode:'',
    };
  },
    validations: {
    },
  mounted() {
    
    this.item = this.data
    this.getCodFAM()
  },

  methods: {
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    convertDate(val){
        this.item.accountingDetails.date =  moment(val).unix()
        this.updateItem()
    },
    getCodFAM(){
      let tk = localStorage.getItem('tk')
      if (this.data) {
        axios.get(`${this.UrlServer}categories/filter/product/desc/IMPIANTI-${this.data.systemSubCategory}`,  
        {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
            if (response.data){
              this.famList = response.data
          } 
        })   
      }
    },
    sendOrder(){
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}projects/${this.ID}`, this.item, {headers: { authorization:this.tk}} )
        .then(()=>{
          alert('Ordine inviato!')
      })
    },
    updateItem(){
      
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}projects/${this.ID}`, this.item, {headers: { authorization:this.tk}} )
          .then(()=>{
            alert('Aggiornamento effettuato')
          })
    },
  },
  components: {
    documentManagerUT,
  },

};
</script>

<template>
    <form action="">
      <div class="row mb-50 back-grey">
                <div class="col-lg-12 col-md-12 mb-20">                                                    
                  <div>   
                    <p class="text-muted bold">Note</p>
                      <p class="text-muted">La registrazione amministrativa si intende automaticamente effettuata con la conferma della stipula del contratto</p>
                                                    
                  </div>
                </div>
            </div>
        <div class="row mb-1 back-grey">
            <div class="col-lg-2 col-md-12">
             
                <label for="contractInput" class="form-label">Stipula contratto</label>
                <select class="form-select"  placeholder="Seleziona stato stipula contratto" data-trigger id="contractInput" v-model="item.accountingDetails.contract"  @change="updateItem">
                    <option value="">Seleziona stato Stipula contratto</option>
                    <option value="false">Non avvenuta</option>
                    <option value="true">Avvenuta</option>
                   
                </select>                      
            </div>
            <div class="col-lg-2 col-md-12">
                <label for="dateRegInput" class="form-label">Data stipula contratto</label>
                <input type="date" class="form-control" id="dateRegInput" v-model="item.accountingDetails.dateStr"  @change="convertDate(item.accountingDetails.dateStr)">
            </div>
            <div class="col-lg-2 col-md-12">
                <label for="modPayRegInput" class="form-label">Modalità di pagamento</label>
                <select class="form-select"  placeholder="Seleziona Modalità di pagamento" data-trigger id="modPayRegInput" v-model="item.accountingDetails.modPay"  @change="updateItem">
                    <option value="">Seleziona Modalità di pagamento</option>
                    <option value="bonifico">Bonifico bancario</option>
                   
                </select>                      
            </div>      
            <div class="col-lg-3 col-md-12">
            
            </div>
        </div>
        <div class="row mb-3 back-grey">
            <div class="col-lg-6">
                <label for="responseMSGInput" class="form-label">Note di registrazione (facoltativo)</label>
                  <textarea class="form-control" id="responseMSGInput" rows="3" placeholder="Inserisci eventuali note sul so" v-model="item.accountingDetails.note" @blur="updateItem"></textarea>                       
            </div>
           
            <div class="col-lg-3">
                                    
            </div>      
            <div class="col-lg-3">
              </div>
        </div>
        <div class="row mb-3 back-grey">
            <div class="col-lg-12">
                <label for="responseMSGInput" class="form-label"><h3>Fatturazione</h3></label>
                
            </div>
        </div>
          <div class="row mb-3 back-grey">
            <div class="col-lg-12">
                <label for="responseMSGInput" class="form-label">Tipologia Impianto</label>:
                <b-badge variant="primary" class="badge-label" ><i class="mdi mdi-circle-medium"></i> <strong>{{ item.systemSubCategory }}</strong></b-badge>
            </div>
          </div>
          <div class="row mb-3 back-grey">
            <div class="col-lg-3">
                <label for="responseMSGInput" class="form-label">Seleziona Caratteristiche</label>:
                  <select class="form-select"  placeholder="Seleziona impianto da fatturare" data-trigger id="billInput" v-model="item.tipoImpiantoFatturato"  @change="updateItem" >
                    <option value="">Seleziona impianto da fatturare</option>
                    <option  v-for="item in famList" :key="item._id" :value="item.CodArt">{{ item.Nome }}</option>
                </select>    
              </div>
              <div class="col-lg-3 ">
                <label for="responseMSGInput" class="form-label">Conferma ordine</label>:
                <br>
                <button type="button" class="btn btn-dark custom-toggle active" data-bs-toggle="button">
                  <span class="icon-on"><i class="ri-bookmark-line align-bottom"></i></span>
                  <span class="icon-off"><i class="ri-bookmark-3-fill align-bottom"></i></span>
                  Conferma
              </button>
              </div>
          </div>  
      
    </form>
    <div class="row mb-3">
        <div class="col-lg-12">
          <template v-if="data">
              <template v-if="data.customer!=null">
                <template v-if="data.customer.length>0">
                 
                  <documentManagerUT 
                    :url="`${this.UrlServer}storage/by/project`"
                    :projectID="data._id" 
                    :data="data" 
                    :bucket="data.customer[0].nomeFascicolo + data.year.toString() + '/' + data.systemSubCategory+'/'" 
                    type="doc-raccolta-dati"
                    refDirectory="raccolta_dati"
                    fileType="multiple" 
                    fileAccept="*" 
                    title="Documentazione impianto"
                    v-on:getParent="getParent()"/>
                </template>
              </template>
            </template> 


        </div>
    </div>




</template>
